import React, { useContext } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";
import { ReactSVG } from "react-svg";

import { getSprinkles } from "~styles/getSprinkles.css";

import { ThemeContext } from "~sections/LayoutSection";

import { type BoxProps } from "../Box";

import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok } from "~types/storyblok.types";

// use original logo colors if the theme is white, sand or mockUiCard
const shouldKeepOriginalColors = (theme: VariantBackgroundColorEnum) => {
  return (
    theme === "background_white" ||
    theme === "background_1" ||
    theme === "background_sand" ||
    theme === "background_2" ||
    theme === "background_mockUiCard"
  );
};

export interface SvgLogoProps extends StoryblokBlok, BoxProps {
  svgLogo: string;
  className?: string;
}

export function SvgLogo({ svgLogo, className, ...rest }: SvgLogoProps) {
  const theme = useContext(ThemeContext);

  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const classNames = clsx(getSprinkles({ ...atomProps }), className);

  const svgUrl = `/images/logos/${svgLogo}.svg#logo`;

  const replaceFillColors = (svg: SVGElement) => {
    if (shouldKeepOriginalColors(theme)) {
      return;
    }

    // currentColor makes the SVG inherit the color passed as CSS style via otherProps
    svg.querySelectorAll("[fill]").forEach((el) => {
      return el.setAttribute("fill", "currentColor");
    });
  };

  return (
    <ReactSVG
      id={`logo_${svgLogo}`}
      viewBox="0 0 288 148"
      src={svgUrl}
      role="img"
      beforeInjection={replaceFillColors}
      className={classNames}
      wrapper="svg"
      {...otherProps}
    />
  );
}
